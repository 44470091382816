import axios from 'axios';
import React from 'react';
import './App.scss';
import Home from './Home/Home';


const App = () => {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;

import React from 'react';
import './Home.scss';
import magnifier from "../icons/magnifier.svg";
import backgroundShape from "../icons/background-shape.svg";

import logo from '../images/logo.png';

const Home: React.FC = () => {
    return (
        <div className="home">
            <div className="background-shape">
                <img src={backgroundShape} alt=""/>
            </div>
            <div className="background-shape-reversed">
                <img src={backgroundShape} alt=""/>
            </div>
            <div className="container">
                <img src={logo} alt="" />
                <div className="slogan">
                    <div>Optimal <span>experience</span></div>
                    <div><span>Maximum</span> enjoyment</div>
                </div>

                <div className="searchbar">
                    <input type="text" placeholder="Search for artists, albums or songs..." />
                    <button><img src={magnifier} alt=""/> </button>
                </div>
            </div>
        </div>
    );
}

export default Home